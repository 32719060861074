@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles for fonts and colors */
@font-face {
  font-family: "Stardom";
  src: url("assets/Stardom-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.font-stardom {
  font-family: "Stardom", serif;
}

html {
  font-size: 20px;

  color: #FDFFFF; /* White text */
  background-color: black; /* Black background */
}

body {
  margin: 0;
  background-color: black;
}

/* Styling for h1, h2, h3, and body text */
h1 {
  font-size: 64px; /* Larger font size for main headings */
  font-weight: 500; /* Normal weight */
  color: #FF9F1C; /* Orange color */
  line-height: 1.4;
}

h2 {
  font-size: 32px; /* Slightly smaller than h1 */
  font-family: "Stardom", serif;
  font-weight: 400;
  color: #FF9F1C; /* White color */
  margin-top: 1.5rem; /* Consistent margin for sections */
  line-height: 1.4;
}

h3 {
  font-size: 24px; /* Slightly smaller than h2 */
  font-weight: 300;
  font-family: "Stardom", serif;
  font-weight: 300;
  color: #FF9F1C; /* Orange color */
  margin-top: 1rem;
  line-height: 1.4;
}

p {
  color: #FDFFFF; /* White text for body */
  line-height: 1.6; /* Increased line spacing for readability */
  font-weight: 100;
}

/* Link Styling */
a {
  color: #FF9F1C; /* Orange color for links */
  text-decoration: underline;
}
